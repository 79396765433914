* {
  margin-bottom: 1rem;
  font-family: multi-display, sans-serif;
  color: black;
}

body {
  background-color: #fafafa;
  justify-content: center;
  align-content: center;
  width: 100%;
  align-items: center;
  margin: 0;
  font-family: multi-display, sans-serif;
  color: white;
  min-height: 100vh !important;
  margin-top: 2rem !important;
}

.divider {
  width: 1px;
  background-color: lightgray;
  margin-left: 20px;
  margin-right: 20px;
  height: 100%;
}

.stickyContainer {
  position: sticky;
  top: 65px;
}

.imageSize {
  width: 25%
} 

.App {
  margin-top: 3rem;
  margin: auto;
  width: fit-content;
  text-align: left;
  font-family: multi-display, sans-serif;
}

.form {
  margin: auto;
  margin-top: 2rem;
  width: 80%;
  position: relative;
  background-color: #EEE;
  border-radius: 1rem;
  padding: 1.5rem;
}

.heading {
  color: black;
  text-align: left;
}

#submit {
  background-color: #0026b9;
  color: white;
  font-size: 1.5rem;
  font-family: multi-display, sans-serif;
  width: 100%;
  height: 3rem;
  border-radius: 10px;
  border: 0;
}

#reset {
  background-color: #c9327b;
  color: white;
  font-size: 1.5rem;
  font-family: multi-display, sans-serif;
  width: 100%;
  height: 3rem;
  border-radius: 10px;
  border: 0;
}

#searchButton {
  background-color: #0026b9;
  color: white;
  font-size: 1.5rem;
  font-family: multi-display, sans-serif;
  width: 100%;
  height: 3rem;
  border-radius: 10px;
  border: 0;
}
#addButton {
  background-color: #c9327b;
  color: white;
  font-size: 1.5rem;
  font-family: multi-display, sans-serif;
  width: 100%;
  height: 3rem;
  border-radius: 10px;
  border: 0;
  margin-top: 1rem;
}

#textInput {
  color: black;
  font-size: 1.5rem;
  font-family: multi-display, sans-serif;
  width: 100%;
  height: 3rem;
  border-radius: 10px;
  border: 0;
  padding: 0;
  text-align: center;
}

#logo {
  width: 75px;
  height: 75px;
  align-self: center;
  margin: 0;
}

.searchField {
  width: 100%;
  margin: 0;
  padding: 0;

}

label {
  font-size: 18px;
  font-weight: 400;
}

table, th, td {
  border: 1px solid;
  padding: 1rem;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navUl {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #000022;
  padding-left: 10px;
  z-index: 1;
}

.navLi {
  float: left;
}

.navLi a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 18px;
  height: 22px;
}

.navLi a:hover {
  color: #CCCCCC;
}

select {
  width: 100%;
}

.head {
  justify-content: space-between;
}

.headingHome {
  color: white;
  text-align: center;
}

.box {
  border: 1px solid #dfdfdf;
  background-color: #fff;
  border-radius: 1rem;
}

.adminHeading {
  color: black;
  font-weight: 400;
}

.active {
  background-color: green !important;
}

.masterContainer {
  width: 100% !important;
}

@media only screen and (max-width: 400px) {
  .masterContainer {
    width: 100% !important;
  }
}

.foodItemContainer {
  background-color: white;
  border-radius: 10px;
  width: 350px;
  height: 110px;
  box-shadow: #666 0px 0px 3px;
  position: relative;
  padding-top: 16px;
  padding-bottom: 16px;
}

.foodItemContainerImage {
  background-color: white;
  border-radius: 10px;
  width: 350px;
  height: 140px;
  box-shadow: #666 0px 0px 3px;
  position: relative;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 128px;
}

.textInList {
  margin-left: 16px;
  text-align: left;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  font-size: 17px;
}

.boldText {
  font-weight: 500
}

.logoInList {
  width: 110px;
  position: absolute;
  top: 0;
  right: 0;
}

.margin4 {
  margin-top: 24px;
}